<template>
  <div id="page-container">
    <div id="modalOpacity" v-show="applyOpacity"></div>
    <div id="recoveryModalWrapper" v-show="showRecoveryModal">
      <div class="recoveryHelper">
        <div class="closeCross" @click="handleRecoveryModal(false)">X</div>
        <label
          v-show="!recoveryEmailSent"
          for="recoverModal"
          class="recoverLabel"
          >{{ $t("forgot_password_modal.header") | capitalize }}</label
        >
        <p>{{ $t("forgot_password_modal.body") | capitalize }}</p>
        <input
          v-show="!recoveryEmailSent"
          v-model.trim="recoveryEmail"
          type="text"
          id="recoveryEmail"
        />
        <div
          class="sendButton"
          :class="{ disabled: recoveryEmail == '', noClick: recoveryEmailSent }"
          @click="sendRecoveryEmail"
        >
          <p>{{ getRecoveryText() | capitalize }}</p>
        </div>
      </div>
    </div>
    <nagi-header />
    <div id="page-body">
      <div id="logoContainer">
        <img id="nagiLogo" src="../assets/icons/nagiZoneLogo.png" />
      </div>
      <div class="info-login text" v-if="activeTab === 'register'">
        <span>{{ $t("unauthenticated.register_tab_text") | uppercase }}</span>
      </div>
      <div id="wrapper">
        <div id="login-register-tabs">
          <!-- <div
            class="button text"
            @click="isActive('login')"
            :class="{ active: activeTab === 'login' }"
          >
            <span>{{ $t("unauthenticated.login_tab_text") | uppercase }}</span>
          </div>
          <div
            class="button text"
            @click="isActive('register')"
            :class="{ active: activeTab === 'register' }"
          >
            <span>{{
              $t("unauthenticated.register_tab_text") | uppercase
            }}</span>
          </div> -->
        </div>
        <div id="login-form-container" v-if="activeTab === 'login'">
          <LoginForm @handle-recovery-modal="handleRecoveryModal" />
        </div>
        <div id="register-form-container" v-else>
          <RegisterForm @onHelpHover="darkenBackground" />
        </div>
      </div>
    </div>
    <nagi-footer />
  </div>
</template>

<script>
import jsonwebtoken from "jsonwebtoken";
import { shhh } from "../config";
import { store } from "../store.js";

import { mapState } from "vuex";

const LoginForm = () => import("../components/LoginForm");
const RegisterForm = () => import("../components/RegisterForm");
const NagiHeader = () => import("../components/NagiHeader");
const NagiFooter = () => import("../components/NagiFooter");

export default {
  name: "Home",
  components: {
    LoginForm,
    RegisterForm,
    NagiHeader,
    NagiFooter,
  },
  data() {
    return {
      token: this.$route.query.token,
      activeTab: this.$route.query.token ? "register" : "login",
      applyOpacity: false,
      showRecoveryModal: false,
      recoveryEmail: "",
      langs: ["es", "en"],
    };
  },
  computed: {
    ...mapState({
      recoveryEmailSent: (state) => state.recoveryEmailSent,
    }),
  },
  created: function() {
    if (this.token) {
      try {
        const userData = jsonwebtoken.verify(this.token, shhh, {
          algorithm: ["HS256"],
        });
        store.commit("setUserData", userData);
      } catch (err) {
        alert(this.$t("register_form.malformated_token"));
      }
    }
  },
  mounted() {
    this.$store.dispatch("requestLoader", false);
  },
  methods: {
    isActive(value) {
      this.activeTab = value;
    },
    backToWebpage() {
      location.replace("https://www.nagismartpool.com/");
    },
    darkenBackground(val) {
      this.applyOpacity = val;
    },
    getRecoveryText() {
      const buttonText = this.recoveryEmailSent ? "sent" : "send";
      return this.$t(`forgot_password_modal.${buttonText}`);
    },
    handleRecoveryModal(val) {
      this.applyOpacity = val;
      this.showRecoveryModal = val;
      this.recoveryText = this.getRecoveryText();
    },
    sendRecoveryEmail() {
      this.$store.dispatch("recoverCredentials", this.recoveryEmail);
      this.$store.dispatch("emailSent", true);
    },
  },
};
</script>

<style scoped>
#page-container {
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url("../assets/backgrounds/bg.jpg");
  background-size: auto 105vh;
  background-position: 60% 0;
  grid-template-rows: 48px 10fr 72px;
  grid-template-columns: 1fr;
  display: grid;
  justify-items: center;
}
#recoveryModalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 17em;
  width: 20rem;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 1px 2px 0px #ccd0db;
  z-index: 2;
  font-size: 1.1rem;
  border-radius: 8px;
}
#recoveryModalWrapper input {
  width: 80%;
  height: 1.2rem;
}
#recoveryModalWrapper label {
  font-weight: bold;
}
.closeCross {
  position: fixed;
  width: 25px;
  height: 25px;
  right: 8px;
  top: 8px;
  font-size: 1rem;
  cursor: pointer;
}
.recoveryHelper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.sendButton {
  margin-bottom: 0;
  cursor: pointer;
  background-color: #2bace3;
  margin-top: 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.noClick {
  pointer-events: none;
  cursor: default;
  margin-top: 0;
}
.sendButton p {
  color: white;
  margin: 0.4rem 1rem;
}
#modalOpacity {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  pointer-events: none;
  z-index: 1;
  opacity: 0.5;
}
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}
#page-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  margin-bottom: 5rem;
  margin-top: 2rem;
}
#logoContainer {
  max-width: 100%;
}
#nagiLogo {
  width: 100%;
  object-fit: contain;
}
.info-login {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2rem 0;
  width: 30rem;
}
.info-login > span {
  padding: 2px;
}
#login-register-tabs {
  display: flex;
  align-self: center;
  width: 30rem;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 white;
  padding-bottom: 4px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  font-size: 0.875rem;
  height: 4rem;
  background-color: #030d2c;
  color: "white";
}
.button:hover {
  cursor: pointer;
}
.active {
  color: #030d2c;
  background-color: white;
}
#login-form-container {
  margin-top: 1.75rem;
}

#register-form-container {
  margin-top: 1.75rem;
}

.help {
  color: white;
  font-size: 1rem;
  user-select: text;
}

@media screen and (max-width: 1024px) {
  #page-container {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 52% 0;
    background-size: cover;
    display: flex !important;
    flex-direction: column;
  }
  #modalOpacity {
    height: 140vh;
    overflow: hidden;
  }

  #register-form-container {
    margin: 1.75rem 16px 0 16px;
  }

  .info-login {
    font-weight: 500;
    font-size: 1.25rem;
  }
  #page-body {
    width: 100%;
  }
  #form-wrapper {
    width: 100%;
  }
  .button {
    width: 100%;
  }
  #login-register-tabs {
    width: 100%;
  }
  #form-wrapper {
    width: 85vw;
    margin: auto;
  }
}
</style>
